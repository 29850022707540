import React from 'react';

const ErrorPage: React.FC = () => {
    return (
        <div className="page">
            ErrorPage
        </div>
    );
};

export default ErrorPage;
